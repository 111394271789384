import Vue from 'vue'

import IndustryService from '@/modules/common/scripts/service-industry'

// Function takes a date string with format Y-m-d H:i:s, e.g. 2018-01-01 13:00:00.
export function shortDate(dateString) {
    if (dateString && dateString.split(' ')[0]) {
        const date = new Date(dateString.split(' ')[0])
        const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
        const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
        return `${day}-${month}-${date.getFullYear()}`
    }
    return dateString
}

// Function takes a date string with format Y-m-d H:i:s, e.g. 2018-01-01 13:00:00.
export function longDate(dateString) {
    if (dateString && dateString.split(' ')[0]) {
        return new Date(dateString.split(' ')[0]).toLocaleDateString('en-ZA', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        })
    }
    return dateString
}

// 2020-03-07 14:24:43
export function formatDateTime(dateString) {
    if (dateString) {
        // The server runs on UTC time
        // First we get a date object we can work with.
        const date = new Date(dateString + ' UTC')

        return date.toLocaleString()
    }
    return dateString
}

// Polyfill for older browsers
if (!window.Intl) {
    window.Intl = {
        NumberFormat: function () {
            return {
                format(value) {
                    if (isNaN(value) || value === null) {
                        value = 0
                    }
                    value = Math.round(value * 100) / 100
                    return ' ' + value.toString()
                },
            }
        },
    }
}

export function formatCurrency(value) {
    const numberFormat = new Intl.NumberFormat('en-ZA', {
        style: 'currency',
        currency: 'ZAR',
        maximumFractionDigits: 2,
    })
    const result = numberFormat.format(value).replace('R', '').replace(',', '.')
    return result
}

export function safeAvatarURL(value) {
    if (value) {
        return value
    }
    return '/images/profile.png'
}

export function confidential(value) {
    if (value) {
        return value
    }
    return 'Confidential'
}

export function truncate(value, charLimit) {
    if (value && value.length > charLimit) {
        return value.slice(0, charLimit) + '...'
    }
    return value
}

// export function transactionStateDisplay(value) {
//     const map = {
//         CREATED: 'Created',
//         INVITATION_SENT: 'Invited',
//         ACCEPTED: 'Accepted',
//         FUNDS_DEPOSITED: 'Funds Deposited',
//         FUNDS_RECEIVED: 'Funds Cleared',
//         INITIATED: 'Delivery Started',
//         DELIVERED: 'Delivered',
//         FUNDS_RELEASED: 'Funds Released',
//         CANCELED: 'Cancelled',
//         DECLINED: 'Declined',
//         ADMIN_CANCELED: 'Admin Cancelled',
//         ADMIN_SUSPENDED: 'Admin Suspended',
//     }
//
//     if (!value) {
//         return value
//     }
//
//     if (!map[value]) {
//         return value.toLowerCase()
//     }
//
//     return map[value]
// }
export function transactionStateDisplay(transaction) {
    const map = {
        CREATED: 'Created',
        INVITATION_SENT: 'Invited',
        ACCEPTED: 'Accepted',
        FUNDS_DEPOSITED: 'Funds Deposited',
        FUNDS_RECEIVED: 'Funds Cleared',
        INITIATED: transaction.workflow === 'DRAWDOWN' ? 'Drawdown Management' : 'Delivery Started',
        DELIVERED: transaction.workflow === 'DRAWDOWN' ? 'Drawdown Management' : 'Delivered',
        FUNDS_RELEASED: transaction.workflow === 'DRAWDOWN' ? 'Transaction Completed' : 'Funds Released',
        CANCELED: 'Cancelled',
        DECLINED: 'Declined',
        ADMIN_CANCELED: 'Admin Cancelled',
        ADMIN_SUSPENDED: 'Admin Suspended',
    }

    if (!transaction) {
        return transaction
    }

    if (!map[transaction.state]) {
        return transaction.state.toLowerCase()
    }

    if (transaction.state === 'INVITATION_SENT') {
        return transaction.metadata.owner ? 'Invitation Sent' : 'Invitation Received'
    }

    return map[transaction.state]
}

export function transactionIndustryDisplay(industry) {
    const industryInstance = IndustryService.getIndustry(industry)
    return industryInstance.displayName
}

export function transactionTypeDisplay(type) {
    const types = {
        STANDARD: 'Single Payment',
        DRAWDOWN: 'Drawdown',
        MILESTONE: 'Milestone Payments',
    }
    if (!types[type]) {
        return type
    }
    return types[type]
}

export function transactionRoleDisplay(role, industry) {
    const industryInstance = IndustryService.getIndustry(industry)
    const map = {
        BUYER: industryInstance.buyerName,
        SELLER: industryInstance.sellerName,
        AGENT: 'Lead Agent',
        BENEFICIARY_ADVISER: 'Adviser',
        BENEFICIARY_CONSULTANT: 'Consultant',
        BENEFICIARY_DELIVERY_COMPANY: 'Delivery Company',
        BENEFICIARY_FINANCIAL_INSTITUTION: 'Financial Institution',
        BENEFICIARY_INTERMEDIARY: 'Intermediary',
        BENEFICIARY_LEGAL_COUNSEL: 'Legal Counsel',
        BENEFICIARY_SUB_AGENT: 'Sub-Agent',
        BENEFICIARY_WHOLESALER: 'Wholesaler',
        BENEFICIARY_OTHER: 'Beneficiary',
        READ_ONLY: 'Read-Only User',
    }

    if (!role) {
        return role
    }

    if (!map[role]) {
        return role.toLowerCase()
    }

    return map[role]
}

export function transactionFeeAllocationDisplay(value, industry) {
    const map = {
        AGENT: transactionRoleDisplay('AGENT', industry),
        BUYER: transactionRoleDisplay('BUYER', industry),
        SELLER: transactionRoleDisplay('SELLER', industry),
        BUYER_SELLER: `${transactionRoleDisplay('BUYER', industry)} & ${transactionRoleDisplay('SELLER', industry)}`,
        BUYER_AGENT: `${transactionRoleDisplay('BUYER', industry)} & ${transactionRoleDisplay('AGENT', industry)}`,
        SELLER_AGENT: `${transactionRoleDisplay('SELLER', industry)} & ${transactionRoleDisplay('AGENT', industry)}`,
        BUYER_SELLER_AGENT: `${transactionRoleDisplay('BUYER', industry)} & ${transactionRoleDisplay(
            'SELLER',
            industry,
        )} & ${transactionRoleDisplay('AGENT', industry)}`,
    }

    if (!value) {
        return value
    }

    if (!map[value]) {
        return value.toLowerCase()
    }

    return map[value]
}

export function upperCaseFirstChar(value) {
    if (value && value.length > 1) {
        return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
    }
    return value
}

import formatDistance from 'date-fns/formatDistance'
import isToday from 'date-fns/isToday'
export function formatDateRelativeFuture(toDate, fromDate = new Date()) {
    toDate = new Date(toDate.replace(/-/g, '/'))
    return formatDistance(fromDate, toDate)
}

export function formatDateRelativePast(fromDate, toDate = new Date()) {
    fromDate = new Date(fromDate.replace(/-/g, '/'))
    return formatDistance(fromDate, toDate) + (isToday(toDate) ? ' ago' : '')
}

export function formatNumber(value) {
    if (!value) {
        return value
    }
    return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
}

export function formatOrganizationSuffix(value) {
    const map = {
        SOLE_PROP: '',
        PUBLIC: 'Ltd',
        PRIVATE: '(Pty) Ltd',
        STATE: 'SOC Ltd',
        NPC: 'NPC',
        TRUST: 'Trust',
        INC: 'Inc',
        CC: 'CC',
        OTHER: '',
    }
    if (!value) {
        return value
    }

    if (!map[value] && map[value] !== '') {
        return value.toLowerCase()
    }

    return map[value]
}

export function transactionDepositTypeDisplay(value) {
    const map = {
        ACC: 'Escrow Account',
        EFT: 'EFT',
        SNAP: 'Snapscan',
        OZOW: 'Ozow',
    }
    if (!value) {
        return value
    }

    if (!map[value] && map[value] !== '') {
        return value.toLowerCase()
    }

    return map[value]
}

Vue.filter('relativeDateFuture', formatDateRelativeFuture)
Vue.filter('relativeDatePast', formatDateRelativePast)

Vue.filter('organizationSuffix', formatOrganizationSuffix)
Vue.filter('confidential', confidential)
Vue.filter('shortDate', shortDate)
Vue.filter('longDate', longDate)
Vue.filter('dateTime', formatDateTime)
Vue.filter('currency', formatCurrency)
Vue.filter('number', formatNumber)
Vue.filter('safeAvatarURL', safeAvatarURL)
Vue.filter('transactionStateDisplay', transactionStateDisplay)
Vue.filter('transactionRoleDisplay', transactionRoleDisplay)
Vue.filter('transactionIndustryDisplay', transactionIndustryDisplay)
Vue.filter('transactionDepositTypeDisplay', transactionDepositTypeDisplay)
Vue.filter('truncate', truncate)
Vue.filter('upperCaseFirstChar', upperCaseFirstChar)
Vue.filter('transactionTypeDisplay', transactionTypeDisplay)
