import { render, staticRenderFns } from "./layout-default.vue?vue&type=template&id=48a36ed6&scoped=true&"
import script from "./layout-default.vue?vue&type=script&lang=js&"
export * from "./layout-default.vue?vue&type=script&lang=js&"
import style0 from "./layout-default.vue?vue&type=style&index=0&id=48a36ed6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48a36ed6",
  null
  
)

export default component.exports